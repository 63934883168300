import React from "react";
import { Link, useScrollRestoration } from "gatsby";

import "./case.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";

const Case = ({location}) => {
    const {caseItem = {}} = location.state || {};
    const {data = {}} = location.state || {};
    const caseScrollRestoration = useScrollRestoration('page-component-case');

    return (
    <div {...caseScrollRestoration}>
        <Header />
        <div className="container case">
            <div className="case-title-container">
                <img src="img/cases/Setra.svg" alt="case-logo"/>
                <h2 className="case-title-container__title">
                    {caseItem.title}
                </h2>
                <div className="case-mark">
                    {caseItem.mark}
                </div>
            </div>
            <div className="case-about-client">
                <img src="img/cases/AboutClient.svg" alt="about-client"/>
                <div className="case-about-client__text">
                    <h3>About the Client</h3>
                    <p>{caseItem.aboutClient}</p>
                </div>
            </div>
            <div className="case-challenges">
                <h3>Challenges we have overcome together</h3>
                <p>{caseItem.generalChallanges}</p>
            </div>
            <div className="case-carousel">
                <img src={caseItem.img} alt={caseItem.title} />
            </div>
            <div className="case-solutions">
                <h3>Solutions</h3>
                <p>{caseItem.solutions}</p>
                <img src="img/cases/technologies.svg" />
            </div>
            <div className="case-results">
                <p>{caseItem.results}</p>
            </div>
        </div>
        <Contact data={data} />
        <Footer />
    </div>
    );
};

export default Case;